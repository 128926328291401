/* --------------------------------------------------------------- */

/*
  This file is used by `lib/core/LcApi.js`; as we're sharing the 
  library with different apps, each app needs to create it's own 
  config.

  Production: https://leadcapture-api.oq.com
  Staging:    https://oq-leadcapture-api.staging.alligence.com
  Asana task: https://app.asana.com/0/1200941836607829/1199969853244253

*/
const LcConfig = {
  // server_url: 'https://oq-leadcapture-api.staging.alligence.com',  /* staging */
  server_url: 'https://leadcapture-api.oq.com',  /* production */
  base_url: 'api/v1',
  api_key: 'ae143b8d42664ceab1d72efcdb6ed893',
};

/* --------------------------------------------------------------- */

export default LcConfig;

/* --------------------------------------------------------------- */
